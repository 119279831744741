<template>
    <el-dialog class="dataMatching" :close-on-click-modal="false" :before-close="handleClose" title="数据匹配"
               :visible.sync="visible" width="60%">
        <div v-if="step == 0">
            <div class="flex_l_c tips">
                <div class="el-icon-document"></div>
                <div>
                    <div>使用说明</div>
                    <div class="text">请提前将资源文件上传至服务器(xxx.xxx.xx.xx)的D:\xx\下，<span class="hint">文件模板；</span></div>
                </div>
            </div>
            <el-form size="small" :model="inputForm" ref="inputForm" label-width="auto">
                <el-form-item label="文件目录：" prop="catalog"
                              :rules="[{required: true, message: '请选择文件目录', trigger: 'change'}]">
                    <el-cascader
                            v-model="inputForm.catalog"
                            :options="catalogOpt"
                            :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'levelOneFolderName', label: 'levelOneFolderName' }"
                            @change="changeCatalog"></el-cascader>
                </el-form-item>

                <!-- 2022.11.03 CL 验收展示关闭 -->
                <!--<el-form-item label="请输入匹配公司：" prop="sourceCompany"
                              :rules="[{required: true, message: '请输入匹配公司', trigger: 'change'}]">
                    <el-input v-model="inputForm.sourceCompany" placeholder="模糊匹配"></el-input>
                </el-form-item>-->

                <el-form-item label="匹配主文件夹名称：" prop="folderName">
                    <el-row>
                        <el-col :span="16" class="m_r1">
                            <el-input disabled v-model="inputForm.folderName" placeholder="精准匹配"></el-input>
                        </el-col>
                        <el-col :span="4">
                            <el-button size="small" type="primary" @click="add">新增</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-row class="text_center b_l b_d">
                    <el-col :span="4" class="b_r p_all1">序号</el-col>
                    <el-col :span="8" class="b_r p_all1">字段名</el-col>
                    <el-col :span="8" class="b_r p_all1">前缀</el-col>
                    <el-col :span="4" class="p_all1">操作</el-col>
                </el-row>
                <el-row v-for="(item, index) in inputForm.list" :key="index" class="text_center b_l b_r b_b p_t15">
                    <el-col :span="4" class="m_t1">{{index+1}}</el-col>
                    <el-col :span="8">
                        <el-form-item :prop="`list.${index}.fieldName`" label-width="0"
                                      :rules="[{required: true, whitespace: true, message: '字段名不能为空', trigger: 'change'}]">
                            <el-select v-model="item.fieldName" placeholder="请选择">
                                <el-option
                                        v-for="item2 in fieldNameOpt"
                                        :key="item2.id"
                                        :label="item2.fieldName"
                                        :value="item2.fieldName"
                                        :disabled="item2.disabled">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :prop="`list.${index}.prefix`" label-width="0">
                            <el-input v-model="item.prefix"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button v-if="index!=0" type="text" @click="del(index)">删除</el-button>
                    </el-col>
                </el-row>
                <el-form-item v-if="this.listType == 0" :label="matchName1" prop="high" class="m_t2"
                              :rules="[{required: true, message: '请填写文件名称', trigger: 'blur'}]">
                    <el-input v-if="this.listType == 0" v-model="inputForm.high" placeholder="模糊匹配"></el-input>
                </el-form-item>
                <el-form-item v-if="this.listType == 0" :label="matchName2" prop="mid"
                              :rules="[{required: true, message: '请填写文件名称', trigger: 'blur'}]">
                    <el-input v-model="inputForm.mid" placeholder="模糊匹配"></el-input>
                </el-form-item>
                <el-form-item v-if="this.listType == 0" :label="matchName3" prop="low"
                              :rules="[{required: true, message: '请填写文件名称', trigger: 'blur'}]">
                    <el-input v-model="inputForm.low" placeholder="模糊匹配"></el-input>
                </el-form-item>
                <el-form-item v-if="this.listType != 0" :label="matchName5" prop="common"
                              :rules="[{required: true, message: '请填写文件名称', trigger: 'blur'}]">
                    <el-input v-model="inputForm.common" placeholder="模糊匹配"></el-input>
                </el-form-item>
                <el-form-item :label="matchName4" prop="thumbnail"
                              :rules="[{required: true, message: '请填写文件名称', trigger: 'blur'}]">
                    <el-input v-model="inputForm.thumbnail" placeholder="模糊匹配"></el-input>
                </el-form-item>
            </el-form>
            <div class="text_center m_t1">
                <el-button size="small" type="primary" @click="beginMatch">开始匹配</el-button>
            </div>
        </div>
        <div v-if="step == 1" class="dataPreview">
            <div class="flex_l_c tips">
                <div class="el-icon-document"></div>
                <div>
                    <div>目录下文件夹数: <span class="greenColor">{{ folderCount }}条</span></div>
                    <div>已匹配文件夹数: <span class="greenColor">{{ successfulMatchCount }}条</span></div>
                    <div>未匹配文件夹数: <span class="redColor">{{ errorMatchCount }}条</span></div>
                </div>
            </div>
            <div>
                <div class="f_w">未匹配情况：<span @click="exportTable()" style="cursor: pointer" class="hint">导出</span></div>
                <el-table
                        :data="fileList"
                        size="small"
                        height="calc(100vh - 440px)"
                        class="table">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column prop="folderName" label="文件夹名称" show-overflow-tooltip/>
                    <el-table-column prop="noMatchInfo" label="未匹配情况" show-overflow-tooltip/>
                </el-table>
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="pageSize"
                        :total="total"
                        background
                        layout="total, prev, pager, next, sizes">
                </el-pagination>
            </div>
            <div class="text_center m_t1">
                <el-button size="small" type="primary" plain @click="step--">上一步</el-button>
                <el-button size="small" type="primary" v-if="successfulMatchCount > 0" @click="dataPreview">数据预览
                </el-button>
            </div>
        </div>
        <div v-if="step == 2">
            <el-table
                    :data="fileList2"
                    size="small"
                    height="calc(100vh - 300px)"
                    class="table">
                <el-table-column prop="sequenceNumber" width="150" sortable label="已匹配数据">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-for="item in scope.row.molders" :key="item">{{ item }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="thumb_path" width="110" sortable label="缩略图">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.thumbPath"
                                  fit="contain"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="resource_name" label="资源名称" sortable show-overflow-tooltip/>
                <el-table-column prop="collection_name" label="藏品名称" sortable show-overflow-tooltip/>
                <el-table-column prop="resource_type" label="资源类型" sortable show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                        </div>
                    </template>
                </el-table-column>
                <!--<el-table-column prop="project_number" label="项目编号" sortable show-overflow-tooltip/>
                <el-table-column prop="file_order_number" label="文件序号" sortable show-overflow-tooltip/>-->
                <el-table-column prop="cultural_relic_id" label="文物编号" sortable show-overflow-tooltip/>
                <el-table-column label="路径" sortable show-overflow-tooltip>
                    <span>{{path}}</span>
                </el-table-column>
                <el-table-column prop="cultural_relic_id" label="大小" sortable show-overflow-tooltip/>
                <el-table-column prop="cultural_relic_id" label="创建时间" sortable show-overflow-tooltip>
                    {{createDate}}
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle2"
                    @current-change="currentChangeHandle2"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total2"
                    background
                    layout="total, prev, pager, next, sizes">
            </el-pagination>
            <div class="text_center m_t1">
                <el-button size="small" type="primary" plain @click="rematch()">重新匹配</el-button>
                <el-button size="small" type="primary" @click="performMatching()">执行匹配</el-button>
            </div>
        </div>
        <div v-if="step == 3">
            <el-result icon="success" title="已添加采集任务">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="goToCollectPage">去采集任务页面</el-button>
                    <el-button type="primary" size="medium" @click="affirm">确认</el-button>
                </template>
            </el-result>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                listType: '',
                step: 0,
                inputForm: {
                    sourceCompany: 999, // 临时公司名称
                    catalog: [],
                    folderName: '',
                    list: [
                        {
                            fieldName: '',
                            prefix: '',
                        },
                    ],
                    high: 'High',
                    mid: 'Mid',
                    low: 'low',
                    common: '',
                    thumbnail: '缩略图',
                },
                catalogOpt: [],
                fieldNameOpt: [
                    {
                        id: '1',
                        label: '项目编号',
                    },
                    {
                        id: '2',
                        label: '文件序号',
                    },
                    {
                        id: '3',
                        label: '文物编号',
                    }
                ],
                // 数据预览
                fileList: [

                ],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                // 执行匹配
                fileList2: [
                    {
                        id: '1',
                        sequenceNumber: 1,
                        collectionLocation: '博物馆',
                        times: '宋',
                    }
                ],
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,

                matchName1: '',
                matchName2: '',
                matchName3: '',
                matchName4: '',
                matchName5: '',
                masterTableIds: [],
                fileNames: {},
                dataKey1: '',
                dataKey2: '',
                dataKey3: '',
                dataKey4: '',
                folderCount: '',
                successfulMatchCount: '',
                errorMatchCount: '',
                errorMatchListCount: '',
                originalId: '',
                title: '',
                path:"",
                createDate:null,
                resourceType: null,
            }
        },
        watch: {
            'inputForm.list': {
                handler(val) {
                    let str = [];
                    this.fileNames = {};
                    this.fieldNameOpt.map(item => {
                        item.disabled = false;
                    })
                    val.forEach(item => {
                        this.fieldNameOpt.forEach(itemC => {
                            if (item.fieldName == itemC.fieldName) {
                                this.fileNames[itemC.fieldEname] = item.prefix;
                                itemC.disabled = true;
                                str.push(item.prefix)
                                str.push(item.fieldName)
                            }
                        })
                    })
                    this.inputForm.folderName = str.join('')
                },
                deep: true
            },
        },
        methods: {
            //导出
            exportTable() {
                if (this.errorMatchCount == 0) {
                    this.$message('没有错误信息可以导出');
                } else {
                    this.$axios(this.api.dataMatching.downloadExcel, {
                        errorFolderNameListCacheKey: this.dataKey2,
                    }, 'get', 'blob').then(res => {
                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
                        // let filename = res.headers['content-disposition']
                        let filename = '错误信息';
                        let link = document.createElement('a')
                        link.download = decodeURI(filename)
                        link.href = window.URL.createObjectURL(blob)
                        link.click()
                    })
                }
            },

            //去采集页面
            goToCollectPage() {
                this.$router.push({path: '#/gather/acquisitionTask'})
                this.affirm();
            },

            //弹窗右上角关闭
            handleClose() {
                this.affirm();
                if (this.step < 3) {
                    this.exit();
                }
            },

            // 确认
            affirm() {
                this.visible = false;
                this.$refs['inputForm'].resetFields();
            },

            //获取下拉框
            getTableByResourceName() {
                this.$axios(this.api.dataMatching.getTableByResourceName, {
                    resourceName: '资源采集/数据匹配',
                }, 'get').then(res => {
                    this.fieldNameOpt = res.data.data;
                    this.originalId = res.data.data[0].masterTable;
                    this.getName();
                })
            },

            //获取所有文件目录
            getContentList() {
                this.$axios(this.api.dataMatching.getContentList, {}, 'get').then(res => {
                    if (res.status) {
                        this.catalogOpt = res.data
                        this.catalogOpt.map(item => {
                            item.children = [];
                            item.levelTwoFolderName.map(towItem => {
                                item.children.push({'levelOneFolderName': towItem})
                            })
                        });
                    }
                })
            },

            //获取资源名称
            getFileName() {
                if (this.listType != 0) {
                    return
                }
                this.$axios(this.api.dataMatching.getFieldByResourceName, {
                    resourceName: '三维资源/数据匹配', //值为（三维资源/数据匹配)
                }, 'get').then(res => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.fieldSort == 1) {
                                // this.masterTableId = item.id;
                                this.masterTableIds.push(item.masterTableId)
                                this.matchName1 = '匹配' + item.fieldName + '文件夹名称：';
                            } else if (item.fieldSort == 2) {
                                this.matchName2 = '匹配' + item.fieldName + '文件夹名称：';
                            } else if (item.fieldSort == 3) {
                                this.matchName3 = '匹配' + item.fieldName + '文件夹名称：';
                            }
                        })
                    }
                })
            },
            //获取资源名称
            getFileName1() {
                this.$axios(this.api.dataMatching.getFieldByResourceName, {
                    resourceName: '三维资源/匹配项',
                }, 'get').then(res => {
                    if (res.status) {
                        res.data.map(item => {
                            this.masterTableIds.push(item.masterTableId)
                            this.matchName4 = '匹配' + item.fieldName + '文件夹名称：';
                            this.matchName5 = '匹配' + this.title + '文件夹名称：';
                        })
                    }
                })
            },

            //获取当前数据匹配全部列名称
            getName() {
                /*this.$axios(this.api.dataMatching.dataMatchCachePreheat, {
                    originalId: this.originalId, //元数据id
                }, 'get').then(res => {
                })*/
            },


            init(id, listType) {
                this.step = 0;
                this.listType = listType;
                this.common = listType;
                this.resourceType = listType;
                this.visible = true;
                this.getContentList();
                this.getFileName1();
                this.getFileName();
                this.getTableByResourceName();
                this.title = this.$dictUtils.getDictLabel("resource_type", listType ,'')
            },

            // 文件目录改变
            changeCatalog(value) {
                let that = this;
                that.path = ''
                value.forEach(function (v) {
                    that.path+=v+"/"
                })
                that.path = that.path.slice(0, -1)
            },

            // 新增
            add() {
                this.inputForm.list.push({
                    fieldName: '',
                    prefix: '',
                })
            },
            // 删除
            del(index) {
                this.inputForm.list.splice(index, 1)
            },

            //获取错误信息
            getDataList() {
                this.$axios(this.api.dataMatching.getList, {
                    listKey: this.dataKey2,
                    'current': this.pageNo,
                    'size': this.pageSize,
                }, 'get').then(data => {
                    this.fileList = data.data;
                })
            },

            //数据预览
            dataPreview() {
                this.step++;
                this.refreshList();
            },


            // 获取数据列表
            refreshList() {
                this.$axios(this.api.dataMatching.getList, {
                    listKey: this.dataKey3,
                    'current': this.pageNo2,
                    'size': this.pageSize2,
                }, 'get').then(data => {
                    this.fileList2 = data.data;
                })
            },
            // 开始匹配
            beginMatch() {
                let selectFolders = []
                if (this.listType != 0) {
                    selectFolders = [
                        this.inputForm.common,
                        this.inputForm.thumbnail
                    ]
                } else {
                    selectFolders = [
                        this.inputForm.high,
                        this.inputForm.mid,
                        this.inputForm.low,
                        this.inputForm.thumbnail]
                }

                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.dataMatching.startMatching, {
                            folderName: this.inputForm.catalog[0],
                            twoFolderName: this.inputForm.catalog[1],
                            masterTableIds: this.masterTableIds,
                            moduleFolders: selectFolders,
                            vagueFolderNames: this.fileNames,
                            originalId: this.originalId,
                            resourceType: this.resourceType,
                            sourceCompany: this.inputForm.sourceCompany,
                        }, 'post').then(res => {
                            if (res.status) {
                                this.createDate = this.formatDate(new Date())
                                this.dataKey1 = res.data[0];
                                this.dataKey2 = res.data[1];
                                this.dataKey3 = res.data[2];
                                this.dataKey4 = res.data[3];
                                this.step++;
                                this.getDataList();
                                this.resultDataConfirmation();
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    }
                })
            },

            //获取信息条数
            resultDataConfirmation() {
                this.$axios(this.api.dataMatching.resultDataConfirmation, {
                    errorFolderNameListCacheKey: this.dataKey1,
                }, 'get').then(res => {
                    if (res.status) {
                        this.folderCount = parseInt(res.data.folderCount);
                        this.successfulMatchCount = parseInt(res.data.successfulMatchCount);
                        this.errorMatchCount = parseInt(res.data.errorMatchCount);
                        this.errorMatchListCount = parseInt(res.data.errorMatchListCount);
                        let errorTotal = 0
                        if (res.data.errorMatchListCount == null) {
                            errorTotal = this.errorMatchCount
                        } else {
                            errorTotal = parseInt(res.data.errorMatchListCount)
                        }
                        this.total = errorTotal;
                        this.total2 = parseInt(res.data.successfulMatchCount);
                        if (this.total == 0) {
                            this.step = 2
                            this.refreshList();
                        }
                    }
                })
            },

            //执行匹配
            performMatching() {
                this.$axios(this.api.dataMatching.save + '?waitDataKey=' + this.dataKey4, {}, 'post').then(res => {
                    if (res.status) {
                        this.step++;
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },

            //重新匹配
            rematch() {
                this.step = 0;
                this.exit();
            },

            //后台删除零时缓存
            exit() {
                let ids = [this.dataKey1, this.dataKey2, this.dataKey3, this.dataKey4];
                this.$axios(this.api.dataMatching.exit, ids, 'post').then(res => {
                })
            },

            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getDataList();
            },
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getDataList();
            },
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                this.refreshList();
            },
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                this.refreshList();
            },
        }
    }
</script>

<style scoped>
    .tips {
        background: #FFEEE6;
        font-weight: 700;
        color: #999999;
        border: 1px solid #FFDDCC;
        border-radius: 5px;
        padding: 20px 15px;
        margin-bottom: 20px;
    }

    .tips .el-icon-document {
        margin-right: 20px;
        font-size: 30px;
        color: #FF992F;
    }

    .tips .text {
        font-weight: 400;
        font-size: 12px;
        padding-top: 5px;
    }

    .hint {
        color: #0000FF;
    }

    .dataPreview .tips {
        color: #333333;
    }
</style>

<template>
    <div class="page" v-if="listType != ''">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" label-width="100px" @keyup.enter.native="search()">
            <el-form-item prop="title" label="关键字查询：">
                <el-input v-model.trim="searchForm.title" maxlength="100" placeholder="请输入资源名称或编号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="searchDates" label="更新时间：">
                <el-date-picker
                        v-model="searchForm.searchDates"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="更新起始时间"
                        end-placeholder="更新结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search()" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-button class="f_r" size="small" type="primary" @click="add" v-show="hasPermission('admin:resources:add')" icon="el-icon-plus">新增</el-button>
        </el-form>
        <div class="bg-white">
            <div class="flex_b_c">
                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList"></AdvancedQuery>
                <div>
                    <el-button v-show="hasPermission('admin:resources:submitCataloguing')" icon="el-icon-position" @click="submitCatalog" size="small" plain>提交编目</el-button>
                    <el-dropdown class="m_r1 m_l1">
                        <el-button size="small" plain icon="el-icon-news">智能操作</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-show="hasPermission('admin:resources:uploadForm')"
                                              @click.native="uploadForm">上传表格</el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:dataMatching')"
                                              @click.native="dataMatching">数据匹配
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:modificationType')" @click.native="modifyType" v-if="listType == 7">修改类型</el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:downloadForms')"
                                              @click.native="downloadForm">下载表格
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:downloadFiles')"
                                              @click.native="downloadFile">下载文件
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:del')"
                                              @click.native="del()">批量删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button size="small" plain icon="el-icon-refresh-left" @click="refreshAll"></el-button>
                    <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                </div>
            </div>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 328px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column
                        type="selection"
                        width="50" fixed :reserve-selection='true'>
                </el-table-column>
                <el-table-column prop="thumb_path" width="110" label="缩略图">
                    <template slot-scope="scope">
                        <el-image class="infoImg" :src="scope.row.thumb_path" fit="contain">
                            <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('../../../../../../assets/img/thumbnail.png')"></el-image>
                            <el-image slot="error" fit="contain" class="infoImg" :src="require('../../../../../../assets/img/thumbnail.png')"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item,index) in columnList" :key="index"
                                 :prop="item.fieldEname" min-width="160"
                                 show-overflow-tooltip sortable
                                 :label="item.fieldName">
                    <template slot-scope="scope">
                        <span v-if="item.fieldEname=='resource_type'">
                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                        </span>
                        <span v-else-if="item.fieldEname=='resource_state'">
                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                        </span>
                        <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                        <span v-else-if="item.fieldEname=='create_user'">
                                            {{scope.row.createUserName}}
                                    </span>
                        <span v-else>{{scope.row[item.fieldEname]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:view')"
                                   @click="view(scope.row)">详情</el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:edit')"
                                   @click="edit(scope.row)">修改</el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:del')"
                                   @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :setShow="setShow" :moduleId="moduleId" @getTbList="getTbList"></DraggablePop>
        <!--新增-->
        <AddForm :resourceType='threeType' ref="addForm" @refreshDataList="refreshList"></AddForm>
        <!--编辑-->
        <EditForm ref="editForm" @refreshDataList="refreshList"></EditForm>
        <!--查看-->
        <ViewForm ref="viewForm"></ViewForm>
        <!--上传表格-->
        <BulkImport ref="bulkImport" @refreshDataList="refreshList" :originalId="moduleId" :resourceType="threeType"
                    :resourceName="resourceName"></BulkImport>
        <!--数据匹配-->
        <DataMatching ref="dataMatching" @refreshDataList="refreshList"></DataMatching>
        <!--修改类型-->
        <el-dialog title="修改资源类型" top="20px" :visible.sync="visible" width="80%" :close-on-click-modal="false" v-if="visible">
            <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" label-width="140px">
                <el-form-item label="资源类型：" prop="resourceType">
                    <el-select v-model="inputForm.resourceType"
                               clearable placeholder="请选择">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('resource_type').filter(v => v.value != '7')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资源清单：" prop="list">
                    <el-table
                            :data="inputForm.list"
                            size="small"
                            height="60vh"
                            class="table" ref="multipleTable">
                        <el-table-column prop="thumb_path" width="110" label="缩略图">
                            <template slot-scope="scope">
                                <el-image class="infoImg" :src="scope.row.thumb_path" fit="contain">
                                    <el-image slot="placeholder" fit="contain" class="infoImg" :src="require('../../../../../../assets/img/thumbnail.png')"></el-image>
                                    <el-image slot="error" fit="contain" class="infoImg" :src="require('../../../../../../assets/img/thumbnail.png')"></el-image>
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column v-for="(item,index) in columnList" :key="index"
                                         :prop="item.fieldEname" min-width="160"
                                         show-overflow-tooltip sortable
                                         :label="item.fieldName">
                            <template slot-scope="scope">
                        <span v-if="item.fieldEname=='resource_type'">
                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                        </span>
                                <span v-else-if="item.fieldEname=='resource_state'">
                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                        </span>
                                <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                                <span v-else-if="item.fieldEname=='create_user'">
                                            {{scope.row.createUserName}}
                                    </span>
                                <span v-else>{{scope.row[item.fieldEname]}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                width="100"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="delEidtData(scope.$index)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button type="primary" @click="confirm()" size="small">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import AddForm from './addForm' // 新增弹窗
    import EditForm from './editForm' // 编辑弹窗
    import ViewForm from './viewForm' // 查看弹窗
    import BulkImport from './bulkImport' // 上传表格弹窗
    import DataMatching from './dataMatching' // 数据匹配弹窗
    export default {
        components: {DraggablePop, AdvancedQuery, AddForm, EditForm, ViewForm, BulkImport, DataMatching},
        props: {
            moduleId: String,
            threeType: Number,// 资源类型
            resourceName: String // 元数据类型名
        },
        data() {
            return {
                searchForm: {
                    title: "",
                    searchDates: [],
                    scene: "",
                },
                sceneOpt: [
                    {
                        label: '全部',
                    },
                    {
                        label: '我上传的文件',
                    }
                ],
                dataList: [],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                listType: '',
                columnList: [],
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景
                // 修改类型
                visible: false,
                inputForm: {
                    resourceIds: [],
                    resourceType: '',
                    list: [],
                },
                dataRule: {
                    resourceType: [
                        {required: true, message: '资源类型不能为空', trigger: 'change'},
                    ],
                }
            }
        },
        activated() {
            this.refreshList()
        },
        watch: {
            searchDates() {
                if (this.searchDates != null) {
                    this.searchForm.beginDate = this.searchDates[0]
                    this.searchForm.endDate = this.searchDates[1]
                } else {
                    this.searchForm.beginDate = ''
                    this.searchForm.endDate = ''
                }
            },
            columnList() {
                this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout();
                })
            }
        },
        methods: {
            init(listType) {
                this.listType = listType
                this.getColumnList()
            },
            // 获取显示字段
            getColumnList() {
                var that = this;
                this.columnList = []
                that.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.logicType == 0) {
                                this.columnList.push(item)
                            }
                        })
                        this.$nextTick(() => {
                            this.loading = false
                            this.$refs.multipleTable.doLayout();
                        })
                    }
                })
            },
            // 刷新按钮回到全部列表
            refreshAll() {
                this.onScene = false
                this.dataListQuery.queryConditions = []
                this.searchForm.title = ''
                this.searchForm.searchDates = []
                this.pageNo = 0
                this.refreshList()
            },
            // 获取数据列表
            refreshList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    this.loading = false
                    return
                }
                //this.searchTpye = 0
                let startTime = null
                let endTime = null
                if (this.searchForm.searchDates != null) {
                    startTime = this.searchForm.searchDates[0]
                    endTime = this.searchForm.searchDates[1]
                }
                this.loading = true
                this.dataListQuery.sceneId = ''
                this.$axios(this.api.rcPage.ReCjList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'resourceNameOrId': this.searchForm.title,
                    'resourceType': this.threeType,
                    'startTime': startTime,
                    'endTime': endTime,
                    'resourceState': 0
                }, 'post').then(res => {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total);
                    this.loading = false
                })
            },
            // 新增
            add() {
              this.$refs.addForm.init('', this.listType);
            },
            // 上传表格
            uploadForm() {
                this.$refs.bulkImport.init();
            },
            // 数据匹配
            dataMatching() {
                if(!this.dataList.length) {
                    this.$message.warning('暂无数据')
                    return
                }
                this.$refs.dataMatching.init('', this.listType);
            },
            // 修改类型
            modifyType() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                this.inputForm.list = this.dataListSelections
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            // 删除修改类型数据
            delEidtData(index) {
                if(this.inputForm.list.length == 1) {
                    this.$message.error("最少保留一条数据")
                    return
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.inputForm.list.splice(index, 1);
                    this.$message.success('删除成功')
                })
            },
            // 确定修改类型
            confirm() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        const h = this.$createElement;
                        let type = this.$dictUtils.getDictLabel("resource_type", this.inputForm.resourceType ,'')
                        this.$msgbox({
                            title: '温馨提示',
                            message: h('p', null, [
                                h('p', {style: 'color: #000000; margin-bottom: 5px'}, '确定将所选资源转为'+type+'？'),
                                h('p', {style: 'color: #999999; font-size: 12px'}, '确定后，相关资源会从其他资源列表中转移到'+type+'列表中')
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(() => {
                            this.inputForm.resourceIds = this.inputForm.list.map(item => {
                                return item.id
                            })
                            let p = JSON.parse(JSON.stringify(this.inputForm))
                            delete p.list
                            p.resourceType = parseInt(p.resourceType)
                            this.$axios(this.api.digital.updataResourcesType, p, 'post').then(data => {
                                if (data.status) {
                                    this.visible = false
                                    this.$message.success("修改成功")
                                    this.refreshList()
                                } else {
                                    this.$message.error("修改失败")
                                }
                            })
                        });
                    }
                })
            },
            //下载表格
            downloadForm() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                let data = {
                    ids: ids,
                    originalId:this.moduleId
                }
                this.exportExcel(this.api.rcPage.exPortList, data, this.resourceName+'列表', 'get')
            },

            // 查看
            view(row) {
                this.$refs.viewForm.init(row.id, this.listType, this.moduleId);
            },
            // 编辑
            edit(row) {
                this.$refs.editForm.init(row.id, this.listType, this.moduleId, row.collection_id, row.resource_type);
            },
            // 提交编目
            submitCatalog() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定提交编目吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.resources.submitCatalog, {'ids': ids}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success("提交成功")
                            this.refreshList()
                        }
                    })
                })
            },
            // 高级查询
            atlGetDataList(data) {
                this.pageNo = 0
                this.getDataList(data)
            },
            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.pageSize
                data.current = this.pageNo
                data.type = this.threeType
                data.searchNumOrName = this.searchForm.title
                data.startTime = this.searchForm.searchDates[0]
                data.endTime = this.searchForm.searchDates[1]
                data.resourceState = 0
                this.$axios(this.api.rcPage.highQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if(!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.rcPage.resourcesRemoveByIds, ids, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.setShow = state;
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }

            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.dataListSelections = []
                this.$refs.multipleTable.clearSelection()
                this.searchDates = []
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.onScene = false
                    this.pageNo = 0
                    this.refreshList()
                })
            },
            search() {
                this.pageNo = 0
                this.refreshList()
            },
            //下载文件
            downloadFile() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$message.info('文件已开始下载，请勿重复操作')
                this.$axios(this.api.rcPage.downloadFile, {ids: ids, module: 0}, 'get', 'blob').then(res => {
                    let blob = new Blob([res.data], {type: 'application/zip'})
                    // let filename = res.headers['content-disposition']
                    let filename = '文件';
                    let link = document.createElement('a')
                    link.download = decodeURI(filename)
                    link.href = window.URL.createObjectURL(blob)
                    link.click()
                })
            },
        }
    }
</script>
<style scoped>
    .infoImg {
        width: 100px;
        height: 100px;
    }
</style>

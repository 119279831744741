<template>
  <el-dialog :append-to-body="true" :title="`查看${title}`" id="detailsPage" :fullscreen="true"
             :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
    <div class="allDialog">
      <div class="infoBox">
        <el-row :gutter="10" class="m_b1">
          <el-card class="box-card">
            <div class="flex_b_c f_w">
              <el-tooltip effect="dark" :content="detailsForm['resource_name']" placement="top-start">
                <div class="omit">{{ detailsForm['resource_name'] }}</div>
              </el-tooltip>
              <el-button type="text" size="medium" @click="resourcesDateShow = !resourcesDateShow">
                {{ resourcesDateShow ? '收起' : '展开' }}
                <i :class="[resourcesDateShow ?'el-icon-arrow-up' : 'el-icon-arrow-down']"
                   style="padding-left: 8px"></i>
              </el-button>
            </div>
            <div :class="[ 'resourcesDate', resourcesDateShow ? 'resourcesDate2' : '']">
              <el-col :span="6" v-for="(item, index) in columnList.filter(item=>{return item.fieldName != '资源名称'})"
                      :key="index">
                <el-col :span="6" class="f_w text_right">{{ item.fieldName }}:</el-col>
                <el-col :span="18">
                  <div v-if="item.fieldEname=='resource_type'">
                    {{ $dictUtils.getDictLabel("resource_type", detailsForm[item.fieldEname], '-') }}
                  </div>
                  <div v-else-if="item.fieldEname=='resource_state'">
                    {{ $dictUtils.getDictLabel("resource_state", detailsForm[item.fieldEname], '-') }}
                  </div>
                  <div v-else-if="item.fieldEname=='on_collection'">
                    {{ detailsForm[item.fieldEname] === 0 ? '否' : '是' }}
                  </div>
                  <div v-else-if="item.fieldEname=='update_user'">
                    {{ detailsForm.updateUserName }}
                  </div>
                  <div v-else-if="item.fieldEname=='create_user'">
                    {{ detailsForm.createUserName }}
                  </div>
                  <el-tooltip effect="dark" :content="detailsForm[item.fieldEname]" placement="top-start" v-else>
                    <div class="omit">{{ detailsForm[item.fieldEname] }}</div>
                  </el-tooltip>
                </el-col>
              </el-col>
            </div>
          </el-card>
          <div v-if="resourcesInfo.onCollection == 1" class="collInfoStyle">
            <el-col :span="6" v-for="(item,index) in collInfoList" :key="index">
              <el-col :span="6" class="f_w text_right">{{ item.fieldName }}:</el-col>
              <el-col :span="18">
                <el-tooltip v-if="item.fieldName == '藏品编号'" effect="dark"
                            :content="resourcesInfo['generalNum'] || resourcesInfo['helpNum']" placement="top-start">
                  <div class="omit">{{ resourcesInfo['generalNum'] || resourcesInfo['helpNum'] }}</div>
                </el-tooltip>
                <el-tooltip v-else effect="dark" :content="resourcesInfo[item.fieldEname]" placement="top-start">
                  <div class="omit">{{ resourcesInfo[item.fieldEname] }}</div>
                </el-tooltip>
              </el-col>
            </el-col>
          </div>
        </el-row>
      </div>
      <el-row class="detailBox">
        <el-col :span="showList ? 18 : 24" class="infoBox p_r">
          <div :class="['showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
               @click="showList = !showList"></div>
          <div class="modelBox text_center" v-if="dataList.length == 0">
            <img src="../../../../../../assets/img/upload.png" class="m_t2">
            <div class="m_t2">暂无资源信息哦~</div>
          </div>
          <div class="modelBox" v-else v-loading="loading">
            <el-carousel :autoplay="imgAutoplay" indicator-position="none" type="card"
                         height="calc(100vh - 290px)" @change="changeImg"
                         v-if="listType == 1">
              <el-carousel-item v-for="(item, index) in dataList" :key="item.id">
                <el-image :src="item.url" fit="contain" :preview-src-list="[item.url]">
                  <el-image slot="placeholder" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                  <el-image slot="error" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <el-row :gutter="10" class="h100" v-else>
              <el-col :span="showList ? 17 : 23" class="h100">
                <iframe allowfullscreen="true" id="frame" :src="this.$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl"
                        style="width: 100%;height: 100%;"></iframe>
              </el-col>
              <el-col v-show="showList" :span="6" class="videoList">
                <el-table
                    :data="dataList"
                    size="small"
                    height="100%"
                    class="pointer" @row-click="clickRow">
                  <el-table-column prop="fileName" show-overflow-tooltip
                                   label="文件名称"></el-table-column>
                </el-table>
                <!--<el-pagination class="text_center m_t1"
                               @size-change="sizeChangeHandle"
                               @current-change="currentChangeHandle"
                               :current-page="pageNo"
                               :page-sizes="[10, 20, 50, 100]"
                               :page-size="pageSize"
                               :total="total"
                               :pager-count="3"
                               small
                               background
                               layout="total, prev, pager, next">
                </el-pagination>-->
              </el-col>
            </el-row>
          </div>
          <div class="flex_b_c">
            <div>
              <el-tag
                  :key="tag"
                  v-for="(tag, index) in dynamicTags" v-if="index < 3"
                  :disable-transitions="false">
                {{ tag.labelName }}
              </el-tag>
              <el-popover v-if="dynamicTags.length > 3"
                          placement="right"
                          width="400"
                          trigger="click">
                <el-tag
                    :key="tag"
                    v-for="(tag, index) in dynamicTags" v-if="index > 2"
                    :disable-transitions="false">
                  {{ tag.labelName }}
                </el-tag>
                <el-button slot="reference" size="small" class="moreBtn">...</el-button>
              </el-popover>
            </div>

            <div>
              <el-button
                  v-if="fileTypeSuffix == 'pdf' && hasPermission('admin:resources:print') && dataList.length > 0"
                  size="small" type="primary" plain icon="el-icon-printer"
                  circle
                  @click="print(info)"></el-button>

              <el-button
                  v-if="hasPermission('admin:resources:download') && dataList.length"
                  size="small" type="primary" plain icon="el-icon-download"
                  circle
                  @click="downloadThree(info)"></el-button>
            </div>
          </div>
        </el-col>

        <el-col :span="6" class="infoBox" v-show="showList">
          <el-form size="small" :model="inputForm" ref="inputForm" label-width="130px" disabled
                   v-if="listType == 1">
            <el-form-item label="事由/题名：" prop="fileName">
              <el-input v-model="inputForm.fileName"></el-input>
            </el-form-item>
            <el-form-item label="照片号/底片号：" prop="photoNumber">
              <el-input v-model="inputForm.photoNumber"></el-input>
            </el-form-item>
            <el-form-item label="时间：" prop="time">
              <el-input v-model="inputForm.time"></el-input>
            </el-form-item>
            <el-form-item label="年度：" prop="resourceYear">
              <el-input v-model="inputForm.resourceYear"></el-input>
            </el-form-item>
            <el-form-item label="地点：" prop="place">
              <el-input v-model="inputForm.place"></el-input>
            </el-form-item>
            <el-form-item label="人物：" prop="resourceCharacter">
              <el-input v-model="inputForm.resourceCharacter"></el-input>
            </el-form-item>
            <el-form-item label="背景：" prop="background">
              <el-input v-model="inputForm.background"></el-input>
            </el-form-item>
            <el-form-item label="拍摄者：" prop="photographer">
              <el-input v-model="inputForm.photographer"></el-input>
            </el-form-item>
            <el-form-item label="作者：" prop="author">
              <el-input v-model="inputForm.author"></el-input>
            </el-form-item>
            <el-form-item label="相册名：" prop="albumName">
              <el-input v-model="inputForm.albumName"></el-input>
            </el-form-item>
            <el-form-item label="主题词/关键词：" prop="keyword">
              <el-input v-model="inputForm.keyword"></el-input>
            </el-form-item>
            <el-form-item label="密级：" prop="classificationLevel">
              <el-input v-model="inputForm.classificationLevel"></el-input>
            </el-form-item>
            <el-form-item label="保管期限：" prop="storagePeriod">
              <el-input v-model="inputForm.storagePeriod"></el-input>
            </el-form-item>
            <el-form-item label="类型规格：" prop="typeSpecifications">
              <el-input v-model="inputForm.typeSpecifications"></el-input>
            </el-form-item>
            <el-form-item label="备注：" prop="remarks">
              <el-input v-model="inputForm.remarks"></el-input>
            </el-form-item>
          </el-form>
          <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px" disabled v-else>
            <el-form-item label="文件名称：" prop="fileName">
              <el-input v-model="inputForm.fileName"></el-input>
            </el-form-item>
            <el-form-item label="文件大小：" prop="fileSizeFormat">
              <el-input v-model="inputForm.fileSizeFormat"></el-input>
            </el-form-item>
            <el-form-item label="文件格式：" prop="fileFormat">
              <el-input v-model="inputForm.fileFormat"></el-input>
            </el-form-item>
            <el-form-item label="文件描述：" prop="fileDesc">
              <el-input v-model="inputForm.fileDesc"></el-input>
            </el-form-item>
              <el-form-item label="配音" prop="photographed" v-if="listType == 2">
                  <el-input v-model="inputForm.photographed"></el-input>
              </el-form-item>
              <el-form-item label="出镜" prop="impersonator" v-if="listType == 3">
                  <el-input v-model="inputForm.impersonator"></el-input>
              </el-form-item>
            <el-form-item label="文案" prop="copywriting" v-if="listType == 2 || listType == 3">
              <el-input v-model="inputForm.copywriting"></el-input>
            </el-form-item>
            <el-form-item label="撰稿" prop="contribution" v-if="listType == 2 || listType == 3">
              <el-input v-model="inputForm.contribution"></el-input>
            </el-form-item>
            <el-form-item label="拍摄人：" prop="shotUser" v-if="listType == 3">
              <el-input v-model="inputForm.shotUser"></el-input>
            </el-form-item>
            <el-form-item label="拍摄日期：" prop="shotTime" v-if="listType == 3">
              <el-input v-model="inputForm.shotTime"></el-input>
            </el-form-item>
            <el-form-item label="拍摄任务：" prop="shotTask" v-if="listType == 3">
              <el-input v-model="inputForm.shotTask"></el-input>
            </el-form-item>
            <el-form-item label="拍摄地点：" prop="shotAddress" v-if="listType == 3">
              <el-input v-model="inputForm.shotAddress"></el-input>
            </el-form-item>
            <el-form-item label="拍摄设备：" prop="shotDevice" v-if="listType == 3">
              <el-input v-model="inputForm.shotDevice"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div slot="footer" class="dialog-footer text_center">
      <el-button size="small" type="primary" v-if="pageType == 'workbench'" v-noMoreClick @click="audit(0)">通过</el-button>
      <el-button size="small" type="primary" v-if="pageType == 'workbench'" v-noMoreClick @click="audit(1)">驳回</el-button>
      <el-button size="small" @click="visible = false">关闭</el-button>
    </div>
    <ResourceAuditForm ref="resourceAuditForm" @refreshDataList="getAudit()"></ResourceAuditForm>
  </el-dialog>
</template>

<script>
import ResourceAuditForm from '../../../resourceManagement/resourceAuditForm'
import {Base64} from 'js-base64'

export default {
  components: {ResourceAuditForm},
  data() {
    return {
      loading: false,
      id: '',
      moduleId: '946049994480156672',
      visible: false,
      imgAutoplay: true,
      listType: '',
      title: '',
      dataList: [],
      pageNo: 1,
      pageSize: 9999,
      total: 0,
      info: {},
      inputForm: {},
      fileUrl: '',
      fileTypeSuffix: '',//文件后缀
      // 标签
      dynamicTags: [],
      detailsForm: {},
      columnList: [
        {
          fieldName: '资源简称',
          fieldEname: 'resource_foshot',
        },
        {
          fieldName: '主题标题',
          fieldEname: 'resource_title',
        },
        {
          fieldName: '成文时间',
          fieldEname: 'written_time',
        },
        {
          fieldName: '资源描述',
          fieldEname: 'resource_desc',
        },
        {
          fieldName: '资源来源',
          fieldEname: 'resource_source',
        },
      ],
      excludeFieldList: [], // 详情需要排除的字段
      onRecycle: 0,//0表示不是从回收站跳过来的，1表示是
      retrievedDetailsPage: false,//首页详情跳转
      showList: false,
      pageType: '',  // 页面类型

      resourcesDateShow: false,
      resourcesInfo: {},
      collInfoList: [
        {
          fieldName: '藏品名称',
          fieldEname: 'collectionName',
        },
        {
          fieldName: '藏品编号',
          fieldEname: 'generalNum',
        },
        {
          fieldName: '藏品类型',
          fieldEname: 'collectionType',
        },
        {
          fieldName: '藏品分类',
          fieldEname: 'oneTypeName',
        },
      ],
      resourcesPreviewFileType: [], // 支持预览的文件格式
    }
  },
  methods: {
    init(id, listType, moduleId, excludeFieldList, retrievedDetailsPage, pageType) {
      this.loading = false
      this.info = {}
      this.fileTypeSuffix = ''
      this.pageType = pageType
      this.listType = listType
      // this.columnList = []
      this.dataList = []
      this.retrievedDetailsPage = retrievedDetailsPage
      if (excludeFieldList != null && excludeFieldList == 1) {
        this.onRecycle = excludeFieldList
        excludeFieldList = null
      }
      this.excludeFieldList = excludeFieldList
      this.id = id
      if (moduleId != null) {
        this.moduleId = moduleId
      }
      this.title = this.$dictUtils.getDictLabel("resource_type", listType, '')
      this.resourcesPreviewFileType = this.getResourcePreviewFileFormat(listType)
      this.visible = true
      this.pageNo = 1
      this.$nextTick(() => {
        this.refreshList()
        this.queryLabelRe()
      })
      this.getResources();
      // this.getColumnList();
    },
    // 水印
    getResourceFilesWatermark() {
      let first = this.info.url.lastIndexOf(".");
      let nameLength = this.info.url.length;
      let fileFormat = this.info.url.substring(first + 1, nameLength).toLowerCase();
      let watermarkFile = ['gif', 'jpg', 'jpeg', 'png', 'pdf']
      if (watermarkFile.indexOf(fileFormat) == -1) {
        return
      }
      this.loading = true
      this.imgAutoplay = false
      this.$axios(this.api.digital.getResourceFilesWatermark, {
        id: this.info.id,
      }, 'get').then(res => {
        if (res.status && res.data) {
          if (res.data.url.indexOf('http') > -1) {
            this.$set(this.info, 'url', res.data.url)
            this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
          }
        }
        setTimeout(() => {
          this.loading = false
          this.imgAutoplay = true
        }, 1000)
      })
    },
    // 获取文件后缀识别文件类型
    getFileType(data) {
      let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
      let namelength = data.length;//取到文件名长度
      this.fileTypeSuffix = data.substring(first + 1, namelength).toLowerCase();//截取获得后缀名
      if(this.resourcesPreviewFileType.indexOf(this.fileTypeSuffix) == -1) {
        this.$message.warning("该文件不支持预览，请下载后本地查看")
      }
    },
    // 审核
    audit(type) {
      if (!this.hasPermission('resourceAuditList:pass') && type == 0 || !this.hasPermission('resourceAuditList:reject') && type == 1) {
        this.$message.info('暂无审核权限，请联系管理员！')
        return
      }
      this.$refs.resourceAuditForm.init([this.id], type)
    },
    getAudit() {
      this.visible = false
      this.$emit('refreshDataList')
    },
    // 数据查询
    refreshList() {
      this.$axios(this.api.rcPage.getResourcesInfo, {
        resourcesId: this.id,
        'current': this.pageNo,
        'size': this.pageSize,
        'delFlag': this.onRecycle
      }, 'get').then(data => {
        if (data.status) {
          this.dataList = data.data.resourcesFileList.records;
          this.total = parseInt(data.data.resourcesFileList.total)
          this.resourcesInfo = data.data.resourcesInfo
          if (this.dataList.length > 0) {
            this.inputForm = this.dataList[0]
            this.info = this.dataList[0];
            if (this.dataList.length) {
              this.getFileType(this.info.url);
              this.getResourceFilesWatermark()
            }
          } else {
            Object.keys(this.inputForm).forEach(key => (this.inputForm[key] = ''))
            Object.keys(this.info).forEach(key => (this.info[key] = ''))
          }
          this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
        }
      })
    },
    // 查询当前资源标签
    queryLabelRe() {
      this.$axios(this.api.rcPage.queryLabelRe, {
        'resourceId': this.id,
      }, 'get').then(res => {
        if (res.status) {
          this.dynamicTags = res.data;
        }
      })
    },
    // 获取显示字段
    // getColumnList() {
    //   this.$axios(this.api.sys.getByOriginalId + this.moduleId, {}, "get").then((res) => {
    //     if (res.status) {
    //       console.log(res)
    //       res.data.map(item => {
    //         if (item.logicType == 0) {
    //           if (this.excludeFieldList != null
    //               && this.excludeFieldList.length > 0
    //               && this.excludeFieldList.indexOf(item.fieldEname) == -1) {
    //             this.columnList.push(item);
    //           } else if (this.excludeFieldList == null || this.excludeFieldList.length == 0) {
    //             this.columnList.push(item);
    //           }
    //         }
    //       })
    //     }
    //   })
    // },
    // 上方获取资源信息
    getResources() {
      this.$axios(this.api.rcPage.getResources + this.id + "/" + this.onRecycle, {}, "get").then((res) => {
        if (res.status) {
          this.detailsForm = res.data
        }
      })
    },

    // 打印
    print(item) {
      this.$print({
        printable: item.url,
        type: item.fileFormat,
        header: null,
        targetStyles: ['*'],
        style: "@page {margin:0 10mm}"
      })
      setTimeout(() => {
        this.getInfo()
        let FirefoxDiv = document.getElementById('printJS')
        if (navigator.userAgent.indexOf("Firefox") > 0) {
          FirefoxDiv.style.cssText = 'width:0;height:0;position:absolute;visibility:hidden;border:0';
        }
      }, 1000)
    },
    downloadThree(info) {
      if (info == null || info.url == null) {
        this.$message.warning("暂无数据！")
        return
      }

      // 发送日志
      this.$axios(this.api.rcPage.saveFileLog, {
        'fileId': info.id,
        'operationType': '下载文件',
        'resourceId': this.id,
        'fileName': info.fileName,
      }, 'post').then(data => {})
      const a = document.createElement('a')
      const url = this.info.url
      fetch(url).then(res => res.blob()).then(blob => {
        a.href = URL.createObjectURL(blob)
        a.download = info.fileName;
        document.body.appendChild(a)
        a.click();
      })
    },
    // 图片切换
    changeImg(val) {
      this.info = this.dataList[val]
      this.inputForm = this.dataList[val]
      this.getFileType(this.info.url);
      this.getResourceFilesWatermark()
    },
    // 点击表格行
    clickRow(val) {
      this.info = val;
      this.inputForm = val
      this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
      this.getFileType(this.info.url);
      this.getResourceFilesWatermark()
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.refreshList()

    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.refreshList()
    },
  }
}
</script>

<style scoped>
.infoBox {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 15px;
  font-size: 13px;
}

.infoBox .f_w {
  color: #333333;
}

.detailBox .infoBox {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.el-carousel__item >>> .el-image {
  height: 100%;
  width: 100%;
}

.el-tabs--border-card >>> .el-tabs__content {
  height: calc(100vh - 232px);
  overflow-y: auto;
}

#detailsPage .modelBox {
  overflow: hidden;
  height: calc(100vh - 290px);
}

.audioBox {
  height: 100%;
  overflow: auto;
}

.detailBox .infoBox::-webkit-scrollbar {
  display: none;
}

.allDialog {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.word-wrap, .xlsx-wrap {
  overflow: auto;
}

.word-wrap >>> img, .xlsx-wrap >>> table {
  width: 100% !important;
}

.xlsx-wrap >>> tr {
  border-bottom: 1px solid #DCDCDC;
}

.xlsx-wrap >>> td {
  padding: 6px;
  text-align: center;
}

.table >>> .cell {
  text-align: center !important;
}

.showListBtn {
  right: 0rem;
}

.resourcesDate {
  height: 18px;
  overflow-y: hidden;
}

.resourcesDate2 {
  height: auto;
}

.collInfoStyle {
  margin-top: 15px;
  padding: 20px;
  height: 60px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>
